
export default {
  name: 'BildBlok',
  props: {
    blok: {
      type: Object,
      required: true,
    },
  },

  computed: {
    floatingStyle() {
      if (!this.blok.floating) {
        return ''
      }
      return { '--img-source': `url(${this.blok.source.filename})` }
    },
  },
}
